import { User } from './User';
import { createAlertMessageEvent } from '@components/app-shell/custom-events';
import AppShell from '@components/app-shell/app-shell';

export const setManagedCompany = async (match: Match) => {
	const { companyuser } = match.data || {};
	await User.setCompany(companyuser || '');
};

export const isAccessAllowed = (ydRoute: Route): boolean => {
	if (!User.canAccess(ydRoute.as, ydRoute.isCompanyUserRequired)) {
		AppShell.getElement()?.dispatchEvent(
			createAlertMessageEvent(window.T.alert.error.notallowed, 'warning'),
		);
		return false;
	}
	return true;
};

export const shouldRedirectToProjectOverview = async (
	ydRoute: Route,
): Promise<boolean> => {
	const companies = (await User.clients) || [];
	if (ydRoute.as === 'home' && companies.length === 1) {
		window.Router.navigate(`/projects/${User.company?.id}`);
		return false;
	}
	return true;
};
