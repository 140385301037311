import { Model } from '@app/Model.js';
import { User } from '@app/User';
import { html, render } from 'lit';
import { Router } from '../../router';
import { commonDefaultRoute, importPage } from '../../router/defaultroute';
import { checkAuth } from '../../router/routeBeforeHooks';
import { getRouteFromMatch } from '../../router/utils/getRouteFromMatch';
import { isUpdateAvailable } from '../../router/utils/isUpdateAvailable';
import {
	isAccessAllowed,
	setManagedCompany,
	shouldRedirectToProjectOverview,
} from './routeBeforeHooks';
import { createAlertMessageEvent } from '@components/app-shell/custom-events';
import AppShell from '@components/app-shell/app-shell';

const manageDefaultRoute: Route = {
	...commonDefaultRoute,
	hasPageTracking: true,
	isCompanyUserRequired: true,
	hasRocketchat: false,
	hooks: {
		...commonDefaultRoute.hooks,
		before: async (done: (arg1: boolean) => void, match: Match) => {
			if (isUpdateAvailable()) {
				done(false);
				window.location.href = `${document.baseURI}${match.url}`;
				return;
			}

			const { ydRoute, query } = getRouteFromMatch(match);
			if (ydRoute.isCompanyUserRequired) await setManagedCompany(match);

			const canNavigate =
				ydRoute &&
				checkAuth(ydRoute) &&
				(await shouldRedirectToProjectOverview(ydRoute)) &&
				isAccessAllowed(ydRoute);
			isAccessAllowed(ydRoute);

			if (canNavigate) {
				// "preload" to abort the navigation in case the preloaded code errors
				ydRoute.templateClass = ydRoute.renderPage
					? await importPage(ydRoute, query)
					: {};
			}

			done(canNavigate && !!ydRoute.templateClass);
		},
	},
};

export const guiRoutes: { [path: string]: Route } = {
	'/cuaccount/:companyuser/:settingsgroup': {
		...manageDefaultRoute,
		as: 'cuaccount',
		isComponent: true,
		hasRocketchat: true,
	},
	'/cuaccount/:companyuser': {
		...manageDefaultRoute,
		as: 'cuaccount',
		isComponent: true,
		hasRocketchat: true,
	},
	'/qualityadd/:companyuser/:formid': {
		...manageDefaultRoute,
		as: 'qualityadd',
		hasRocketchat: true,
	},
	'/qualityadd/:companyuser': {
		...manageDefaultRoute,
		as: 'qualityadd',
		hasRocketchat: true,
	},
	'/quality/:companyuser/:talentid/:formid': {
		...manageDefaultRoute,
		as: 'quality',
		isComponent: true,
		hasRocketchat: true,
	},
	'/quality/:companyuser/:formid': {
		...manageDefaultRoute,
		as: 'quality',
		isComponent: true,
		hasRocketchat: true,
	},
	'/quality/:companyuser': {
		...manageDefaultRoute,
		as: 'quality',
		isComponent: true,
		hasRocketchat: true,
	},
	'/qualityview/:companyuser/:talentid/:formid': {
		...manageDefaultRoute,
		as: 'qualityview',
		hasRocketchat: true,
	},
	'/qualitycheck/:companyuser/:talentid/:formid/:prefillInt': {
		...manageDefaultRoute,
		as: 'qualitycheck',
		hasRocketchat: true,
	},
	'/qualitycheck/:companyuser/:talentid/:formid': {
		...manageDefaultRoute,
		as: 'qualitycheck',
		hasRocketchat: true,
	},
	'/mapping/:companyuser': {
		...manageDefaultRoute,
		as: 'mapping',
		isComponent: true,
		hasRocketchat: true,
	},
	'/teams/:companyuser/:teamId': {
		...manageDefaultRoute,
		as: 'teams',
		isComponent: true,
		hasRocketchat: true,
	},
	'/teams/:companyuser': {
		...manageDefaultRoute,
		as: 'teams',
		isComponent: true,
		hasRocketchat: true,
	},
	'/workforce/:companyuser/:moduleId/:teamIds/:contractState': {
		...manageDefaultRoute,
		as: 'workforce',
		isComponent: true,
		hasRocketchat: true,
	},
	'/workforce/:companyuser/:moduleId/:contractState': {
		...manageDefaultRoute,
		as: 'workforce',
		isComponent: true,
		hasRocketchat: true,
	},
	'/workforce/:companyuser/:moduleId': {
		...manageDefaultRoute,
		as: 'workforce',
		isComponent: true,
		hasRocketchat: true,
	},
	'/workforce/:companyuser': {
		...manageDefaultRoute,
		as: 'workforce',
		isComponent: true,
		hasRocketchat: true,
	},
	'/shifts/:companyuser/:calendarId/:moduleId': {
		...manageDefaultRoute,
		as: 'shifts',
		isComponent: true,
		hasRocketchat: true,
	},
	'/shifts/:companyuser/:calendarId': {
		...manageDefaultRoute,
		as: 'shifts',
		isComponent: true,
		hasRocketchat: true,
	},
	'/shifts/:companyuser': {
		...manageDefaultRoute,
		as: 'shifts',
		isComponent: true,
		hasRocketchat: true,
	},
	'/access/:companyuser': {
		...manageDefaultRoute,
		as: 'access',
	},
	'/members/:companyuser': {
		...manageDefaultRoute,
		as: 'members',
		isComponent: true,
		hasRocketchat: true,
	},
	'/watchlists/:companyuser/:listId': {
		...manageDefaultRoute,
		as: 'watchlists',
		isComponent: true,
		hasRocketchat: true,
	},
	'/watchlists/:companyuser': {
		...manageDefaultRoute,
		as: 'watchlists',
		isComponent: true,
		hasRocketchat: true,
	},
	'/monitor/:companyuser': {
		...manageDefaultRoute,
		as: 'monitor',
		isComponent: true,
		hasRocketchat: true,
	},
	'/dialerkpi/:companyuser': {
		...manageDefaultRoute,
		as: 'dialerkpi',
		isComponent: true,
		hasRocketchat: true,
	},
	'/mailbox/:companyuser/:conversationId': {
		...manageDefaultRoute,
		isComponent: true,
		as: 'mailbox',
		hasRocketchat: true,
	},
	'/mailbox/:companyuser': {
		...manageDefaultRoute,
		isComponent: true,
		as: 'mailbox',
		hasRocketchat: true,
	},
	'/news/:companyuser': {
		...manageDefaultRoute,
		as: 'news',
		isComponent: true,
	},
	'/talent/:talentId': {
		...manageDefaultRoute,
		as: 'talent',
		hasRocketchat: true,
	},
	'/chatgroups/:companyuser': {
		...manageDefaultRoute,
		as: 'chatgroups',
	},
	'/chat/:companyuser/:mnemonic': {
		...manageDefaultRoute,
		as: 'chat',
	},
	'/stats_talents/:companyuser': {
		...manageDefaultRoute,
		as: 'stats_talents',
		hasRocketchat: true,
	},
	'/stats/:companyuser': {
		...manageDefaultRoute,
		as: 'stats',
		hasRocketchat: true,
	},
	'/report/:companyuser': {
		...manageDefaultRoute,
		as: 'report',
		isComponent: true,
	},
	'/customreport/:companyuser': {
		...manageDefaultRoute,
		as: 'customreport',
		isComponent: true,
	},
	'/customreport/:companyuser/:queryid': {
		...manageDefaultRoute,
		as: 'customreport',
		isComponent: true,
	},
	'/findtalents/:companyuser': {
		...manageDefaultRoute,
		as: 'findtalents',
		isComponent: true,
		hasRocketchat: true,
	},
	'/balance/:companyuser': {
		...manageDefaultRoute,
		as: 'balance',
		isComponent: true,
		hasRocketchat: true,
	},
	'/voicefile/:voiceid': {
		...manageDefaultRoute,
		as: 'voicefile',
		isComponent: true,
		hasRocketchat: true,
	},
	'/voicefiles/:companyuser': {
		...manageDefaultRoute,
		as: 'voicefiles',
		isComponent: true,
		hasRocketchat: true,
	},
	'/terminationprices': {
		...manageDefaultRoute,
		as: 'terminationprices',
		isComponent: true,
	},
	'/earnings': {
		...manageDefaultRoute,
		as: 'earnings',
		isComponent: true,
		hasRocketchat: true,
	},
	'/finance/:result': {
		// Paypal charge success url
		...manageDefaultRoute,
		as: 'paypalCallback',
		renderPage: false,
		uses: async (match: Match) => {
			const { query, params } = getRouteFromMatch(match);
			const { token } = query;
			const { result } = params;
			if (result === 'ppsuc' && token) {
				const { success } = await Model.data.finishpayment({
					token,
					method: 'paypal',
					companyuser: User.company?.id,
				});
				if (success) {
					AppShell.getElement()?.dispatchEvent(
						createAlertMessageEvent(window.T.alert.success.ppsucc),
					);
				}
			} else {
				AppShell.getElement()?.dispatchEvent(
					createAlertMessageEvent('Payment failed', 'error'),
				);
			}
			Router.navigate(`/balance/${User.company?.id}`);
			Router.navigate(`/balance/${User.company?.id}`);
		},
		hasRocketchat: true,
	},
	'/projects/:companyuser': {
		...manageDefaultRoute,
		as: 'projects',
		isComponent: true,
		hasRocketchat: true,
	},
	'/project/:companyuser/:mnemonic/:settingsgroup': {
		...manageDefaultRoute,
		as: 'project',
		isComponent: true,
		hasRocketchat: true,
		hooks: {
			...manageDefaultRoute.hooks,
		},
	},
	'/redirecting/hubspot?:code': {
		...manageDefaultRoute,
		as: 'redirectHubspot',
		hasRocketchat: true,
		renderPage: false,
		uses: async (match: Match) => {
			const { query } = getRouteFromMatch(match);

			if (query.code && query.state) {
				const apiData = {
					companyuser: User.company?.id,
					exchangeCode: query.code,
					service: query.state,
				};
				const redirectPath = `/cuaccount/${User.company?.id}/extensions`;

				const { success } = await Model.data.externalauthentication(apiData);

				const redirectUrl = `${redirectPath}${!success ? `?extension=${query.state}&status=failed` : ''}`;

				Router.navigate(redirectUrl);
			}
		},
	},
	'/project/:companyuser/:mnemonic': {
		...manageDefaultRoute,
		as: 'project',
		isComponent: true,
		hasRocketchat: true,
	},
	'/project/:companyuser': {
		...manageDefaultRoute,
		as: 'project',
		isComponent: true,
		hasRocketchat: true,
	},
	'/training/:trainingId': {
		...manageDefaultRoute,
		as: 'training',
		isComponent: true,
		hasRocketchat: true,
	},
	'/training': {
		...manageDefaultRoute,
		as: 'training',
		isComponent: true,
		hasRocketchat: true,
	},
	'/trainings/:trainingId': {
		...manageDefaultRoute,
		as: 'trainings',
		isComponent: true,
		hasRocketchat: true,
	},
	'/trainings': {
		...manageDefaultRoute,
		as: 'trainings',
		isComponent: true,
		hasRocketchat: true,
	},
	'/vpncode': {
		...manageDefaultRoute,
		as: 'vpncode',
		isComponent: true,
	},
	'/account': {
		...manageDefaultRoute,
		as: 'account',
		isComponent: true,
		hasRocketchat: true,
	},
	'/addservice': {
		...manageDefaultRoute,
		as: 'addservice',
		isComponent: true,
		hasRocketchat: true,
	},
	'/lists/:companyuser/:moduleId/:jobid/:subtemplate/:callfilter': {
		...manageDefaultRoute,
		as: 'lists',
		isComponent: true,
		hasRocketchat: true,
	},
	'/lists/:companyuser/:moduleId/:jobid/:subtemplate': {
		...manageDefaultRoute,
		as: 'lists',
		isComponent: true,
		hasRocketchat: true,
	},
	'/lists/:companyuser/:moduleId/:jobid': {
		...manageDefaultRoute,
		as: 'lists',
		isComponent: true,
		hasRocketchat: true,
	},
	'/lists/:companyuser/:moduleId': {
		...manageDefaultRoute,
		as: 'lists',
		isComponent: true,
		hasRocketchat: true,
	},
	'/ibcalls/:companyuser/:moduleId': {
		...manageDefaultRoute,
		as: 'ibcalls',
		isComponent: true,
		hasRocketchat: true,
	},
	'/talentmonitor/:companyuser': {
		...manageDefaultRoute,
		as: 'talentmonitor',
		isComponent: true,
	},
	'/bonuses/:companyuser': {
		...manageDefaultRoute,
		as: 'bonuses',
		isComponent: true,
	},
	'/bonuses/:companyuser/:bonuslistId': {
		...manageDefaultRoute,
		as: 'bonuses',
		isComponent: true,
	},
	'/tfa': {
		...manageDefaultRoute,
		as: 'tfa',
		isCommonRoute: true,
		isCompanyUserRequired: false,
		isComponent: true,
		hasCaptcha: true,
		hooks: {
			...manageDefaultRoute.hooks,
			leave: (canLeave) => {
				canLeave(true);
				manageDefaultRoute.hooks?.leave?.(canLeave);
				render(
					html`
						<!-- -->
					`,
					window.appElement.mainElement,
				);
			},
		},
	},
	'/custom-db-queries': {
		...manageDefaultRoute,
		as: 'custom-db-queries',
		isCompanyUserRequired: false,
		isComponent: true,
	},
	'/': {
		...manageDefaultRoute,
		as: 'home',
		isComponent: true,
		hasRocketchat: true,
	},
};
