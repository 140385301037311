import { Model } from '@app/Model.js';
import { BaseUser } from '../User';
import type { User } from '../User';

interface enrichedCompanyUser extends APIcompany {
	modules: object;
	volumenwarn: [];
}

interface ManageUser extends User {
	clients: Promise<enrichedCompanyUser[]>;
}

class UserManage extends BaseUser implements ManageUser {
	company: APIcompany | null = null;

	get clients() {
		return (async () => {
			if (!this.hasSession) return [];
			const { users = [], modules, volumewarn } = await Model.stored.users();
			if (!users.length) return users;
			return users
				.sort((a: APIcompany, b: APIcompany) =>
					a.company.localeCompare(b.company),
				)
				.map((u: APIcompany) => ({
					...u,
					dashboardSettings: u.dashboardSettings || [],
					modules: modules[u.id] || {},
					volumewarn: volumewarn.filter((warn) => warn.userId === u.id),
				}));
		})();
	}

	get teams() {
		return (async () => {
			if (!this.hasSession || !this.company) return [];
			const { teams = [] } = await Model.stored.teams({
				companyuser: this.company?.id,
				action: 'list',
			});
			return teams;
		})();
	}

	get watchlists() {
		return (async () => {
			if (!this.hasSession || !this.company?.id || !this.hasRight('workforce'))
				return {};
			const { lists } = await Model.stored.preselectlist({
				action: 'list',
				companyuser: this.company.id,
			});
			return lists;
		})();
	}

	get trainings() {
		return (async () => {
			if (!this.hasSession || !this.company?.id || !this.hasRight('workforce'))
				return [];
			const { trainings } = await Model.stored.training({
				action: 'list',
				companyuser: this.company.id,
			});
			return trainings;
		})();
	}

	get isSelfservice() {
		return this.company?.productPlan === 'selfservice';
	}

	get isManaged() {
		return this.company?.productPlan === 'managedservice';
	}

	get hasDollar() {
		return (
			this.user.lang === 'en' && this.user.email?.endsWith('+demo@yoummday.com')
		);
	}

	get currencySymbol() {
		return this.hasDollar ? '$' : '€';
	}

	async setCompany(id = '') {
		const companies = await this.clients;
		const wantedCompany =
			companies.find((u) => u.id === id) || // requested company by id
			(this.company?.id ? this.company : null) || // already set company
			companies.find((u) => u.dashboardSettings.chosen) || // last time chosen company
			companies.find((u) => u.me) || // identified company by 'me' property
			companies[0]; // first company in list

		if (!wantedCompany) return {};

		let { modules = [], calendarIds = {} } = await Model[
			this.company?.id !== wantedCompany.id ? 'data' : 'stored'
		].modules({
			companyuser: wantedCompany.id,
		});
		calendarIds = Object.entries(calendarIds);
		modules = Object.assign(
			{},
			...modules
				.filter((mod) => mod.active)
				.sort((a, b) =>
					(a.title || window.T.term.untitled).localeCompare(
						b.title || window.T.term.untitled,
					),
				)
				.map((mod) => ({
					[mod.mnemonic]: {
						...mod,
						calendarId: calendarIds.length
							? calendarIds.reduce(
									(returnId, [calId, mnemonics]) =>
										`${returnId}${
											mnemonics.includes(mod.mnemonic) ? calId : ''
										}`,
									'',
								)
							: null,
					},
				})),
		);
		wantedCompany.modules = modules;

		if (this.company?.id && this.company?.id !== wantedCompany.id) {
			Model.Storage.removeAll(['statics', 'users']);
			const { settings: dashboardSettings } = await Model.data.settings({
				userid: this.company?.id,
				setting: {
					chosen: '',
				},
			});
			this.mutateCompany({ dashboardSettings }, this.company.id);
			this.company = wantedCompany;

			const { settings } = await Model.data.settings({
				userid: this.company?.id,
				setting: {
					chosen: 1,
				},
			});
			this.mutateCompany({ dashboardSettings: settings }, this.company?.id);
		}
		this.company = wantedCompany;
		return wantedCompany;
	}

	mutateCompany(companyProps = {}, id = '') {
		if (!id || id !== this.company?.id) return;
		const { users } = Model.Storage.get('users') || {};
		const companyPosition = users.findIndex((u) => u.id === id);
		users[companyPosition] = Object.assign(this.company, companyProps);
		Model.Storage.update('users', { users });
	}

	async addCompany(company = {}) {
		if (Object.keys(company).length) {
			Model.Storage.remove('users');
			await this.clients;
		}
	}

	async refreshModules() {
		Model.Storage.remove('modules');
		await this.setCompany();
	}

	canAccess(view = '', companyUserRequired: boolean = true) {
		if (!this.company?.id && companyUserRequired) return false;
		const accessMap = new Map([
			[
				// these routes can be accessed if
				['stats', 'stats_talents'],
				// dashboardRights includes at least one of these
				['stats', 'statslimited'],
			],
			[['workforce'], ['workforce']],
			[['bonuses'], ['bonuspay']],
			[['report'], ['stats', 'statslimited', 'report']],
			[['schedule', 'shifts'], ['calendar']],
			[
				['quality', 'qualityadd', 'qualitycheck', 'qualityview'],
				['qualitycheck'],
			],
			[['voicefiles'], ['voicefile', 'qualitycheck']],
			[['users'], ['adminpm']],
			[['watchlists', 'trainings', 'talentmonitor', 'teams'], ['workforce']],
			[
				['news', 'chatgroups'],
				['workforce', 'message'],
			],
			[['balance'], ['invoice']],
			[['mailbox'], ['message']],
			[['cuaccount'], ['owner']],
			[['project', 'addservice'], ['modulemanage']],
			[['ibcalls', 'lists'], ['curelations']],
			[['earnings'], ['stats']],
			[['mapping'], ['mapping']],
			[['vpncode'], ['dailylogintoken']],
		]);
		const accessDefinition = [...accessMap.keys()].find((viewArr) =>
			viewArr.includes(view),
		);
		if (!accessDefinition) return true;
		return accessMap
			.get(accessDefinition)
			?.some((right) => this.hasRight(right));
	}

	hasRight(right = '') {
		return (
			!!right &&
			!!this.company?.dashboardRights &&
			this.company.dashboardRights.includes(right)
		);
	}
}

const User = new UserManage();

export { User };
